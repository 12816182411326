import API from "../API.js";
import LoginCheck from "../LoginCheck.js";
export default function Blogs(){
	console.log("asdf");
	function Bruh(){
		console.log("eerste");
		LoginCheck();
		console.log("doing");
		const [penis] = API("cock");
		console.log("done");
		return <p>a</p>;
	}
	function BlogClick(){
		console.log("brubby");
		LoginCheck();
		API("asoifdj");
		return <h1 onClick={Bruh}>Spoopy</h1>;
	}
	return (<BlogClick />);
};

