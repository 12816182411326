import API from "../API.js";
export default function SteamLogin(){
	function ExecLogin(){
		var query = "login/steam";
		if(window.location.href.indexOf("?") != -1){
			query = query + "?" + window.location.href.split("?")[1];
		}
		var [json] = API(query, {method: "GET"});
		if(json){
			if(json.loggedin){
				//alert(JSON.stringify(json));
				document.cookie="token=" + json.token + ";path=/;";
				window.location.href=window.location.origin + "/" + (window.location.href.indexOf("returnto=") != -1 ? window.location.href.split("returnto=")[1].split("&")[0] : "");
			}
			else{
				window.location.href = json.loginurl;
			}
		}
	}
	return (<ExecLogin />);
};
