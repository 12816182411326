import { useState, useEffect } from "react";
import { redirect } from "react-router-dom";
const LoginCheck = (requirelogin = true) => {
	console.log("LoginCheck");
	//const [token, setToken] = useState(null);
	//var tmptoken = token;
	var tmptoken = window.token;
	//console.log("old " + tmptoken);
	if(tmptoken == null){
		//console.log("making new token");
		tmptoken = document.cookie.match("(^|;)\\s*token\\s*=\\s*([^;]+)");
		tmptoken = (tmptoken ? tmptoken.pop() : null);
		//setToken(tmptoken);
		window.token = tmptoken;
	}
	//console.log("logcheck " + tmptoken);
	if(tmptoken == null && requirelogin){
		redirect("/login/steam?returnto=" + window.location.pathname.slice("1"));
		return tmptoken;
	}
	return tmptoken;
};

export default LoginCheck;
