import { useState, useEffect } from "react";
import LoginCheck from "./LoginCheck.js";
const API = (endpoint, postdata = {}) => {
	const [data, setData] = useState(null);
	var token = LoginCheck(false);
	var getreq = postdata["method"] == "GET";
	if(token != null){
		postdata["token"] = token;
	}
	var params = {};
	params["headers"] = {};
	params["method"] = (getreq ? "GET" : "POST");
	if(!getreq){
		params["body"] = new URLSearchParams(Object.entries(postdata)).toString();
		//params["body"] = Object.keys(postdata).map((key) => {return encodeURIComponent(key) + '=' + encodeURIComponent(postdata[key]);}).join('&');
		params["headers"]["Content-Type"] = "application/x-www-form-urlencoded";
	}
	useEffect(() => {
		fetch("https://api.gshosting.naleksuh.com/" + endpoint, params).then(
		(response) => response.json()).then(
		(data) => setData(data))
	}, [endpoint]);
	if(data && data.loginrequired){
		document.cookie="token=deleted;expires=Thu, 01 Jan 1970 00:01:09 GMT";
		window.location.reload();
		return [data];
	}
	return [data];
};

export default API;
